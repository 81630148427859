import React, { useRef } from 'react';
import './Home.css';
import logo from './logo.png';
import computer from './computer.png';
import graph from './graph.png';
import emailIcon from './email.png';

function Home() {
	const connectRef = useRef(null);
	const infoRef = useRef(null);
	const scrollToSection = (ref) => {
		ref.current.scrollIntoView({ behavior: 'smooth' });
	};

	return (
		<div className="home-container">
			<div className="header">
				<img src={logo} className="App-logo" alt="App Logo" />
				<p className="contact-text" onClick={() => scrollToSection(connectRef)}>
					Contact Us
				</p>
			</div>
			<div className="head-container">
				<div className="head-text-container">
					<p className="head-text">Fully Automated Algo Trading</p>
					<p className="subtitle">
						Unlock Financial Freedom with Automated Trading
					</p>
					<div
						className="learn-more-button"
						onClick={() => scrollToSection(infoRef)}
					>
						Learn More
					</div>
				</div>
				<img src={computer} className="computer" alt="Computer" />
			</div>
			<br></br>
			<br></br>
			<div className="line"></div>
			<div className="discover" ref={infoRef}>
				<div className="information-container">
					<p className="information-text">
						Over the past 5 years, if Astai’s AI trading system had been applied
						to SPY, a popular S&P 500 ETF, it would have generated a staggering
						return of{' '}
						<strong style={{ textDecoration: 'underline', color: '#EEF7F7' }}>
							1,120%
						</strong>
						. To put this into perspective, SPY itself has only returned{' '}
						<strong style={{ textDecoration: 'underline', color: '#EEF7F7' }}>
							186%
						</strong>{' '}
						during the same period. That means Astai’s AI delivered{' '}
						<strong style={{ textDecoration: 'underline', color: '#EEF7F7' }}>
							over 6 times
						</strong>{' '}
						the market return.
					</p>
				</div>
				<img src={graph} className="graph" alt="Graph" />
			</div>
			<br />
			<div className="line"></div>
			<div className="connect-container" ref={connectRef}>
				<h2>Connect with us</h2>
				<div className="email-container">
					<img src={emailIcon} className="email" alt="Email Icon" />
					<p className="email-text">info@astaifinance.com</p>
				</div>
			</div>
		</div>
	);
}

export default Home;
